.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    background: #333638CC;
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    transition: opacity 250ms ease-in-out;
}

.overlay--after-open {
    opacity: 1;
}

.overlay--before-close {
    opacity: 0;
}