@import "../../../styles/tools";

.container {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
}

.container--no-margin {
    margin-bottom: 0;
}

.container--valign-top {
    align-items: flex-start;
}

.label {
    text-align: left;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size--normal;
    line-height: 22px;
    vertical-align: text-top;
    font-weight: 300;
    color: $color-title-active;

    @include theme-dark {
        color: #FFF;
    }
}

.input {
    display: none;
}

.checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #FFF;
    border-radius: 5px;
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    overflow: hidden;
    transition: all 0.185s ease-in-out;
}

.checkbox--tick {
    border: 2px solid $color-grey-light;

    &::before {
        display: block;
        content: ' ';
        width: 16px;
        height: 16px;
        background: url('./icon-minus.svg');
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center;

        transition: all 0.185s ease-in-out;
    }

    &.checkbox--checked {
        border: 2px solid $color-primary;
    }

    &.checkbox--checked::before {
        display: block;
        content: ' ';
        width: 16px;
        height: 16px;
        background: url('./icon-checkmark--green.svg');
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.checkbox--square {
    border: 2px solid $color-title-active;

    &::before {
        display: block;
        content: ' ';
        width: 12px;
        height: 12px;
        background: $color-primary;
        border-radius: 3px;

        transform: translateY(150%);
        transition: all 0.185s ease-in-out;
    }

    &.checkbox--checked::before {
        transform: translateY(0);
    }
}

.container--disabled {
    pointer-events: none;
    opacity: 0.4;

    .label {
        color: #ADB1B6;
    }
}

.error {
    position: absolute;
    font-size: 13px;
    color: $color-error;
}

.checkbox--error {
    border-color: $color-error;

    &::before {
        display: block;
        content: ' ';
        width: 16px;
        height: 16px;
        background-size: 16px;
        background: url('./icon-minus--red.svg') no-repeat center;

        transition: all 0.185s ease-in-out;
    }
}

.container:not(.container--disabled) {
    &:hover {
        .checkbox {
            background: darken(#FFF, 10%);

        }

        @include theme-dark {
            .checkbox {
                background: darken(#1E2021, 10%);
            }
        }
    }
}

@include media-mobile-xs {
    .label {
        font-size: 14px;
        line-height: 18px;
    }
}

@include theme-dark {
    .checkbox {
        background: #1E2021;
    }
}