@import "../../../styles/tools";

.container {

}

.container--margin {
    margin-bottom: 12px;
}

.tab-list {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.tab-list--border {
    border-bottom: 2px solid $color-input-background;
}

.tab-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background: none;
    padding: 8px 18px 12px;
    font-size: $font-size--small;
    line-height: 20px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    color: $color-title-active;
    font-weight: 300;
    transition: all 0.25s ease-in-out;
    gap: 10px;

    &:hover, &:active {
        color: $color-title-active;
        border-bottom-color: darken($color-primary, 10%);
        background: rgba(0, 0, 0, 0.032);
    }

    &.tab-item--wide {
        flex: 1;
        justify-content: center;
        text-align: center;
    }

    &.tab-item--dots {
        border-bottom: none;
        color: $color-primary;
        display: flex;
        flex-direction: column;

        &::after {
            display: block;
            width: 100%;
            content: ' ';
            height: 1px;
            border-bottom: 1px dashed $color-primary;
        }

        &:hover, &:active {
            &::after {
                border-bottom-color: darken($color-primary, 10%);
            }
        }
    }

    &--border {
        margin-bottom: -1px;
    }
}

.tab-item__container {
    display: flex !important;
    align-items: center;
    margin: 0 auto;
}

.tab-item__icon {
    width: 14px;
    height: 14px;
    margin-bottom: -2px;
    object-fit: contain;

    &.tab-icon--size-small {
        width: 10px;
        height: 10px;
    }

    &.tab-icon--size-large {
        width: 20px;
        height: 20px;
    }

    &.tab-item__icon--left {
        margin-right: 10px;

        &.tab-icon--size-small {
            margin-right: 5px;
        }

        &.tab-icon--size-large {
            margin-right: 15px;
        }
    }

    &.tab-item__icon--right {
        margin-left: 10px;

        &.tab-icon--size-small {
            margin-left: 5px;
        }

        &.tab-icon--size-large {
            margin-left: 15px;
        }
    }
}

.tab-item--light {
    font-size: $font-size--small;
    font-weight: 300;
}

.tab-item--active {
    border-bottom: 2px solid $color-primary;
    color: $color-title-active;

    &.tab-item--dots {
        color: inherit;

        &::after {
            border-bottom-color: transparent;
        }
    }
}

.tab-item__badge--title {
    margin-left: 5px;
}

.actions {
    display: flex;
    margin-left: 20px;
    align-items: center;
}

/**
 * Button styles
 */

 .tab-list--type-button {
    gap: 12px;
 }

 .tab-item--type-button {
    border-radius: 12px;
    padding: 8px 20px;
    border: 1px solid $color-grey--light;
    background: $color-off-white;
    font-weight: 500;
    color: $color-title-active;

    &.tab-item--active {
        background: rgba($color-primary, 4%);
        color: $color-primary;
        border-color: $color-primary;
    }

    &:hover {
        background: rgba($color-primary, 4%);
        color: $color-primary;
        border-color: $color-primary;
    }
 }

/**
 * Button rounded styles
 */

.tab-list--type-button-rounded {

}

.tab-item--type-button-rounded {
    flex: 1;
    border: 2px solid $color-input-background;
    background: $color-off-white;
    padding: 10px 18px;
    min-width: 120px;
    text-align: center;
    justify-content: center;

    &.tab-item--active {
        color: $color-primary;
        font-weight: 500;
        border-bottom-color: $color-primary;
    }

    &:not(:last-child) {
        border-right: none;
    }

    &:first-child {
        padding-left: 32px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    &:last-child {
        padding-right: 32px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }
}

/**
 * Inline styles
 */

.tab-list--type-inline {
    padding: 20px 15px 15px;
    gap: 12px;
}

.tab-item--type-inline {
    margin: 0;

    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: $color-grey-dark;
    padding: 6px 10px 5px;
    border-radius: 5px;
    border: none;
    font-weight: 700;

    &.tab-item--active {
        background: $color-background;
        color: $color-title-active;
    }
}

.tab-items--nowrap {
    gap: 10px;
    white-space: nowrap;
    padding-bottom: 10px;
}

.tab-list--size-large {
    padding: 0 10px;
    height: 74px;
    align-items: flex-end;
}

.tab-item--size-large {
    padding: 20px;
    font-size: $font-size--large;
}

.tab-list--size-small {
    align-items: flex-end;
}

.tab-item--size-small {
    padding: 5px 10px;
    //font-size: $font-size--xsmall;
}

.delete-icon {
    height: 16px;

    &--container {
        margin-right: -12px;
    }
}