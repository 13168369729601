@import "../../../styles/tools";

.icon {
    min-width: 36px;
    height: 36px;
    border-radius: 8px;
    box-shadow: 0px 0px 16px 0px #7676761C;
    display: flex;
    justify-content: center;
    align-items: center;

    --icon-fill-color: #8F8F8F;

    &--active {
        --icon-fill-color: #{$color-primary};
    }

    svg path {
        fill: var(--icon-fill-color);
        transition: all 0.2s ease-in-out;
    }
}

.container {
    background: $color-off-white;
    border-radius: 12px;
    padding: 16px;
}

.header {
    display: flex;
    flex-direction: row;
    width: calc(100% + 32px);
    border-radius: 12px;
    align-items: center;
    gap: 12px;
    margin: -16px;
    padding: 16px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: $color-background;
        box-shadow: 0px 0px 16px 0px #7676761C;
    }

    &:active {
        background-color: darken($color-background, 5%);
    }
}

.control-button {
    width: 32px;
}

.control-icon {
    transition: all 0.2s ease-in-out;

    &--expanded {
        transform: rotate(-90deg);
    }

    &--collapsed {
        transform: rotate(90deg);
    }
}

.name {
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.name, .amount {
    color: $color-title-active;
    font-size: 16px;
    font-weight: 500;
}

.amount {
    flex: 1;
    font-weight: 600;
    text-align: right;
}

.amount--type {
    &-success {
        color: $color-success;
    }

    &-danger {
        color: $color-error;
    }
}

.accounts {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 4px;
}

.accounts__hint {
    color: $color-grey--dark;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
    margin-bottom: 8px;
}