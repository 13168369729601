@import "../../styles/tools";

.container {
    --user-side-panel--width: 455px;
    --user-side-panel--z-index: 2000;
    --user-side-panel--horizontal-padding: 16px;
    --user-side-panel--vertical-padding: 20px;

    display: flex;
    flex-direction: column;
    width: var(--user-side-panel--width);
    height: 100vh;
    position: fixed;
    top: 0;
    left: calc(100vw - var(--user-side-panel--width));
    background: #F6F6F6;
    box-shadow: -4px 0px 30px rgba(0, 0, 0, 0.3);
    z-index: var(--user-side-panel--z-index);

    // TODO Extract to common
    transition: all 0.3s ease-in-out;

    @include media-mobile {
        left: 0;
        width: 100vw;

        --user-side-panel--horizontal-padding: 15px;
    }
}

.container--hidden {
    left: calc(100vw + var(--user-side-panel--width));
    box-shadow: none;

    @include media-mobile {
        left: 100vw;
    }
}

.controls {
    padding: var(--user-side-panel--vertical-padding) var(--user-side-panel--horizontal-padding) 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media-mobile {
        padding-bottom: 0;
    }
}

.close-button {
    font-size: 16px !important;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0.3px;
}

.content {
    padding: 12px var(--user-side-panel--horizontal-padding);
}

.header {
    padding: 12px var(--user-side-panel--horizontal-padding);
    // Добавляем z-index для корректной работы тени вместе со скроллом
    z-index: 1;

    &--shadow {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    }
}

.footer {
    padding: 12px var(--user-side-panel--horizontal-padding) var(--user-side-panel--vertical-padding);
    // Добавляем z-index для корректной работы тени вместе со скроллом
    z-index: 1;

    &--shadow {
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
    }
}