@import "../../styles/tools";

.container {
    display: inline-block;
    background: $color-red--text;
    width: 14px;
    height: 14px;
    max-width: 14px;
    max-height: 14px;
    min-width: 14px;
    min-height: 14px;
    border-radius: 7px;
    overflow: hidden;
    text-decoration: none !important;

    color: $color-off-white;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    vertical-align: middle;
}