@import "../../styles/tools";

.container {
    --user-icon--size: 38px;
    
    position: relative;
    width: var(--user-icon--size);
    margin-right: 5px;
}

.container--size {
    &-large {
        --user-icon--size: 62px;

        .icon__initials {
            font-size: 28px;
            line-height: 38px;
        }
    }
}

.icon {
    width: var(--user-icon--size);
    height: var(--user-icon--size);
    background-color: $color-off-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.icon__image {
    width: var(--user-icon--size);
    height: var(--user-icon--size);
    border-radius: 50%;
}

.icon__default {
    width: calc(var(--user-icon--size) - 10px);
    height: calc(var(--user-icon--size) - 10px);
}

.icon__initials {
    user-select: none;
    color: $color-off-white;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.5px;
}

.badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(30%);
}
