@import "../../styles/tools";

@mixin badgeLightStyle($color, $textColor: #FFF) {
    background-color: $color;

    span {
        color: $textColor;
    }

    &:hover {
        background-color: darken($color, 10%);
    }

    &:active {
        background-color: darken($color, 15%);
    }
}

@mixin badgeDarkStyle($color, $textColor: #FFF) {
    background-color: $color;

    span {
        color: $textColor;
    }

    &:hover {
        background-color: lighten($color, 10%);
    }

    &:active {
        background-color: lighten($color, 15%);
    }
}

.badge {
    padding: 14px 16px;
    font-size: $font-size--normal;
    line-height: $font-size--normal;
    font-weight: 300;
    text-transform: capitalize;
    border-radius: $border-radius;

    text-decoration: none;
    transition: all 0.2s ease-in-out;

    span {
        display: inline-block;
        color: $color-off-white;
    }
}

.badge--type-bronze {
    @include badgeLightStyle(#C99D00);
}

.badge--type-silver {
    @include badgeLightStyle(#D1D1D1);
}

.badge--type-gold {
    @include badgeLightStyle(#EEC840);
}

.badge--type-platinum {
    @include badgeDarkStyle(#190038);
}

.badge--type-diamond {
    @include badgeDarkStyle(#5BBEB8);
}

.badge--type-base {
    @include badgeLightStyle(#0666eb);
}

.badge--type-regular {
    @include badgeLightStyle($color-primary);
}

.badge--type-premium {
    @include badgeDarkStyle(#4d287c);
}

.badge--type-ultra {
    @include badgeDarkStyle(#1b1b1c);
}

@include media-mobile {
    .badge {
        font-size: 12px;
        line-height: 12px;
        padding: 8px 8px 7px;
        height: auto;
    }
}

@include media-mobile-xs {
    .badge {
        display: none;
    }
}