@import "../../../styles/tools";

.container {
    margin-bottom: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.title {
    color: $color-title-active;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.3px;
    font-weight: 600;
}

.action {
    text-align: right;
}

.users {
    display: flex;
    flex-direction: column;
    gap: 8px;
}