@import "../../../styles/tools";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.name {
    margin-top: 8px;
    color: $color-title-active;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(var(--user-side-panel--width, 400px) - var(--user-side-panel--horizontal-padding, 50px) * 2);

    @include media-mobile {
        max-width: 90%;
        font-weight: 400;
    }
}