@import "../../styles/tools";

.container {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    min-width: 412px;

    @include media-tablet {
        min-width: unset
    }
}

.button {
    background: transparent;
    padding: 5px 7px 5px;
    display: flex;
    gap: 8px;
    border: none;
    cursor: pointer;
    text-align: left;
    align-items: center;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }

    &:active {
        background: rgba(0, 0, 0, 0.1);
    }
}

.button__wrapper {
    margin-right: 8px;
}

.button__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-width: 150px;
    max-width: 310px;
}

.button__name {
    color: $color-title-active;
    font-weight: 300;
    font-size: $font-size--normal;
    line-height: $font-size--normal;
    color: $color-body;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 14px;
}

.button__sub {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: $color-body;
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@include media-tablet-portrait {

    .container {
        gap: 16px;
    }

    .button__wrapper {
        margin-right: 0;
    }

    .button__content {
        display: none;
    }
}

@include media-mobile {

    .container {
        gap: 0;
        justify-content: space-between;
    }

    .button {
        flex-wrap: wrap;
        justify-content: center;
        padding: 7px 12px;
        background: rgba(0, 0, 0, 0.05);
        gap: 10px;
    }

    .button__tail {
        margin-left: 10px;
    }

    .button__content {
        display: flex;
        min-width: fit-content;
    }
}

@media screen and (max-width: 390px) {
    .button__wrapper {
        display: none;
    }
}