@import "../../../styles/tools";

.container {
    display: flex;
    margin-bottom: 12px;
    cursor: pointer;

    &:not(.container--disabled) {
        &:hover {
            .radio {
                background: darken(#FFF, 10%);
            }

            @include theme-dark {
                .radio {
                    background: darken(#1E2021, 10%);
                }
            }
        }
    }
}

.container--no-margin {
    margin-bottom: 0;
}

.label {
    text-align: left;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size--normal;
    line-height: 22px;
    vertical-align: text-top;
    font-weight: 300;
    color: $color-title-active;

    @include theme-dark {
        color: #FFF;
    }

    &.label--bold-values {
        font-size: 17px;
        letter-spacing: 0.75px;

        &.label--checked {
            font-weight: 700;
            letter-spacing: normal;
        }
    }
}

.input {
    display: none;
}

.radio {
    display: inline-flex;
    background: $color-off-white;
    border: 2px solid $color-grey-light;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
    overflow: hidden;
    margin-top: -2px;
    transition: all 0.185s ease-in-out;
    justify-content: center;
    align-items: center;

    &--primary {
        &::before {
            background: $color-primary;
        }

        &.radio--checked {
            border-color: $color-primary;
        }
    }

    &--error {
        &::before {
            background: $color-error;
        }

        &.radio--checked {
            border-color: $color-error
        }
    }

    &--default {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;

        &::before {
            width: 16px;
            height: 16px;
        }
    }

    &--small {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;

        &::before {
            width: 10px;
            height: 10px;
        }
    }

    &::before {
        display: block;
        content: ' ';
        border-radius: 50%;

        transform: scale(0.0);
        transition: all 0.185s ease-in-out;
    }

    &.radio--checked {
        &::before {
            transform: scale(1.0);
        }
    }
}

.container--disabled {
    pointer-events: none;
    opacity: 0.4;

    .label {
        color: #ADB1B6;
    }
}

@include media-tablet-portrait {
    .checkbox {
        min-width: 32px;
        min-height: 32px;

        &::before {
            width: 32px;
            height: 32px;
            background-size: 20px;
        }

        &.radio--checked {
            
        }
    }
}

@include media-mobile-xs {
    .label {
        font-size: 14px;
        line-height: 18px;
    }
}

@include theme-dark {
    .radio {
        background: #1E2021;

        &--checked {
            border-color: #20836D;
        }

        &:before {
            background: #20836D;
        }
    }
}