@import "~@ui/styles/tools";

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
}

.start-button {
    width: 100%;
    padding: 0;
}

.start-button__text {
    font-size: 18px;
}

.footer {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.input {
    width: 100%;
}

@include media-mobile {
    .input {
        min-height: 100px;
    }

    .container {
        max-width: 100%;
        flex-direction: column;
        align-items: center;
    }
}