@import "../../../styles/tools";

.container {
    padding: 12px 16px;
    border-radius: 12px;
    margin: 0;

    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #F7F7F7;
    }

    &:active {
        background-color: darken( #F7F7F7, 5%);
    }
}

.container--active {
    background-color: #F7F7F7;

    &:hover {
        background-color: darken( #F7F7F7, 5%);
    }

    &:active {
        background-color: darken( #F7F7F7, 10%);
    }
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    width: 100%;
}

.name {
    flex: 1;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.name, .amount {
    color: $color-title-active;
    font-weight: 500;
    font-size: 14px;
}

.amount {
    font-weight: 600;
    text-align: right;
}

.amount--type {
    &-success {
        color: $color-success;
    }

    &-danger {
        color: $color-error;
    }
}
